//
//         サイトのベースレイアウト
//
//メディアクエリ
//$mqss: 0px;
// $mqs: 320px;
// $msm: 480px;
// $mqm: 640px;
// $mql: 768px;
// $mqx: 960px;
//    @include max-screen($mql){ //タブレット
  //  @include max-screen($mqm){ //スマホ
  //  @include max-screen($mqxlX) { //PC
// ===================================== =============
html{

}
body{
  .allwrap{
    overflow: hidden;
  }
  .img-resp{
    width: auto;
    max-width: 100%;
    height: auto;
  }
  /*============================
   ヘッダー
  ============================*/
  //ヘッダーdrop
  &.floatmode{
    .checktap{
      a:hover + .dropdown,.dropdown:hover{
          top: 113px;
      }
    }
  }
  .checktap{
    a:hover + .dropdown,.dropdown:hover{
        display: block;
        position: absolute;
        width: 100%;
        left: 0;
        top: 143px;
        background: #333;
    }
    .dropdown{
      display: none;
      padding: 10px 0;
      ul{
        max-width: 1000px;
        margin: 0 auto;
      }
      ul li a{
        color: #fff;
        &:hover{
          color: #fff;
          text-decoration: none;
        }
      }
    }
  }
  h1{
    background: $base_color_red;
    color: #fff;
    max-height: 40px;
    @include fontsize(15);
    text-align: center;
    letter-spacing: 0.025em;
    padding: 10px 0;
    margin: 0;
     @include max-screen($mqxlX) { //PC
      display: none;
     }
  }
  header{
    position: relative;
    z-index: 100;
    background: #fff;
    width: 100%;
  }//header
  .header-box{
    display: inline-block;
    min-height: 145px;
    width: 100%;
    height: auto;
    @include max-screen($mqxlX) { //PC
      min-height: 80px;
    }
  }//header-box
  .head-upbox{
    width: 100%;
    height: 100px;
    max-width: 1000px;
    margin: 0 auto;
    position: relative;
    transition-property:height;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    @include max-screen($mqxlX) { //PC
          border-bottom: solid 1px #ccc;
    }
    @include max-screen($mqxlX) { //PC
        height: 80px;
    }
    .hlogo{
      padding:20px 0;
      margin:0 auto;
      display: block;
      width: 242px;
      height: auto;
      img{
        width: 100%;
        height: auto;
      }
      @include max-screen($mqxlX) { //PC
        width: 180px;
        margin:0 auto;
        padding-left: 10px;
        padding-top: 25px;
      }
      @include max-screen($mqm){ //スマホ
        margin:0;
      }
    }
  }//head-upbox
  //フォントボックス
  .font-box{
    width: 230px;
    height: 45px;
    background: #ccc;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    right:0;
    -webkit-transform: translate(0%,-50%);
    -moz-transform: translate(0%,-50%);
    -ms-transform: translate(0%,-50%);
    -o-transform: translate(0%,-50%);
    transform: translate(0%,-50%);
    @include max-screen($mqxlX) { //PC
     display: none;
    }
    span{
      @include fontsize(15);
      position: absolute;
      top: 0;
      bottom: 0;
      margin:auto 0;
      left: 10px;
      height: 1em;
      line-height: 1;
    }//span
    .pushbtn{
      @include fontsize(15);
      display: inline-block;
      padding: 8px 0;
      min-width: 55px;
      background: #333;
      color: #fff;
      border-radius: 17px;
      text-align: center;
      top: 50%;
      position: absolute;
      -webkit-transform: translate(0%,-50%);
      -moz-transform: translate(0%,-50%);
      -ms-transform: translate(0%,-50%);
      -o-transform: translate(0%,-50%);
      transform: translate(0%,-50%);
      line-height: 1;
      cursor: pointer;
      &:hover{
        background: #fff;
        color: $base_color_red;
      }
      &:nth-of-type(1){
          right: 70px;
          // top: 6px;
      }
      &:nth-of-type(2){
          right: 10px;
          // top: 6px;
      }
      &.ActiveFont{
        background: $base_color_red;
        color: #fff!important;
      }
    }
  }//font-box
  //Gナビ
  .gnavi{
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
    height: auto;

    @include max-screen($mqxlX) { //PC
     display: none;
    }
    ul{
      list-style: none;
      width: 100%;
      height: 100%;
      display: table;
      margin: 0;
      padding: 0;
    }
    li{
      display: table-cell;
      // float: left;
      // width: 12.5%;
    }
    ul li a{
      @include fontsize(15);
      display: inline-block;
      width: 100%;
      text-align: center;
      color: #333;
      padding:  10px 0;
      position: relative;
      &::after{
        content:"";
        display: inline-block;
        width: 0;
        height: 3px;
        position: absolute;
        bottom: 0;
        left: 0;
        right:0;
        margin: auto;
        background: $base_color_red;
        transition-property:width;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        text-decoration: none;
      }
      &:hover{
        text-decoration: none;
        color: $base_color_red;

        &::after{
          content:"";
          display: inline-block;
          width: 100%;
          height: 3px;
          background: $base_color_red;
          transition-property:width;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          transition-delay: 0s;
        }
      }
    }
  }//.gnavi
  //動画ボックス
  .movie-box{
    width: 100%;
    position: relative;
    height: calc(100vh - 145px);
    background: #000;
    overflow: hidden;
    min-height: 450px;
    background-image:url(/assets/images/bikebg.jpg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: 10;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    @include max-screen($mqxlX) { //PC
      height: calc(100vh - 80px);

    }
    .text-on{
      letter-spacing: 0.25em;
      color: #fff;
      width: 100%;
      text-align: center;
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      -o-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);

      @include fontsize(40);
      @include max-screen($mqxlX) { //PC
        @include fontsize(35);
        letter-spacing: 0.15em;
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(30);
        letter-spacing: 0.1em;
      }
      span{
        @include fontsize(26);
        @include max-screen($mqxlX) { //PC
          @include fontsize(23);
        }
        @include max-screen($mqm){ //スマホ
          @include fontsize(17);
        }
      }
    }
    #video-background {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      -o-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
      min-width: 100%;
      min-height: 100%;
      width: auto;
      height: auto;
      @include max-screen($mqxlX) { //PC
        display: none!important;
      }
    }
  }//movie-box
  //ヘッダー追従モード
  &.floatmodeA{
    header{
      background: #fff;
      position: fixed;
      top: -135px;
      height: 115px;
      transition-property:top;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
      @include max-screen($mqxlX) { //PC
        top: -120px;
      }
        @include max-screen($mqm){ //スマホ
          top: -120px;
        }
    }
  }
  &.floatmode{
    padding-top: 100px;
    @include max-screen($mqxlX) { //PC
      padding-top: 80px;
    }
    header{
      position: fixed;
      background: #fff;
      transition-property:top;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
      height: 115px;
      @include max-screen($mqxlX) { //PC
        height: 80px;
      }
    }
    .head-upbox{
          height: 70px;
          transition-property:height;
          transition-duration: 0.5s;
          transition-timing-function: ease;
          transition-delay: 0s;
          @include max-screen($mqxlX) { //PC
            height: 80px;
          }

    }
    .header-box{
      min-height: 115px;
      @include max-screen($mqxlX) { //PC
        min-height: 80px;
      }
    }
    header{
      position: fixed;
      top: -65px;
      left: 0;
      background: #fff;
      width: 100%;
      background: $base_color_red;
      @include max-screen($mqxlX) { //PC
        top: 0;
        background: #fff;
      }
      .gnavi ul li a{
        color: #fff;
        &::after{
          background: #fff;
        }
        &:hover{
          color: #fff;
          &::after{
            background: #fff;
          }
        }

      }

    }
    .hlogo{
      margin: 0;
    }
  }//floatmode
  //ハンバーガーメニュー
  .hnbg-menu{
    height: 80px;
    width: 80px;
    position: absolute;
    right: 0;
    top: 0;
    line-height: 0;
    transition-property:right;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    @include min-screen($mqxlX) { //PC
      display: none;
    }
    span,&::before,&::after{
      display: inline-block;
      width: 40px;
      height: 4px;
      background: #333;
      position: absolute;
      left: 50%;
      -webkit-transform: translate(-50%,0%);
      -moz-transform: translate(-50%,0%);
      -ms-transform: translate(-50%,0%);
      -o-transform: translate(-50%,0%);
      transform: translate(-50%,0%);
      border-radius: 5px;
      transition-property:transform,left,top;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    span{
      top: 45%;
    }
    &::before{
      content:"";
      top: 30%;
    }
    &::after{
      content:"";
      top: 60%;
    }
  }
  //ハンバーガー開閉挙動
  &.menu-mode{
    &.floatmodeA{
      header{

        @include min-screen($mqxlX) { //PC
                top: -145px;
        }
      }
      &.floatmode{
        .head-upbox{
          @include min-screen($mqxlX) { //PC
            height: 100px;

          }
        }
        header{

          @include min-screen($mqxlX) { //PC
                      top: -95px;
                      border-bottom: none;
          }
        }
      }
    }
    header{
      position: fixed;
      top: 0;
      left: 0;
      height: 80px;
      width: 100%;
      z-index: 250;
      border-bottom: solid 1px #ccc;
      @include min-screen($mqxlX) { //PC
          top: 0px;
          height: 145px;
      }

    }
    .hnbg-nakami{
      position: fixed;
      top: 80px;
      width: 100%;
      display: inline-block;
      transition-property:top;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
      ul{
        height: 100vh;
        overflow-scrolling: touch;
        -webkit-overflow-scrolling: touch;
      }
    }
    .clickhere{
      a{
        position: relative;
      }
      a span{
        position: absolute;
        display: inline-block;
        width: 15px;
        height: 15px;
        position: absolute;
        top: 50%;
        right:12px;
        -webkit-transform: translate(0%,-50%);
        -moz-transform: translate(0%,-50%);
        -ms-transform: translate(0%,-50%);
        -o-transform: translate(0%,-50%);
        transform: translate(0%,-50%);
        &::before,&::after{
          content:"";
          display: inline-block;
          width: 15px;
          height: 2px;
          background: #ccc;
          position: absolute;
          top: 7px;
          left: 0;
          transition-property:transform;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          transition-delay: 0s;
        }
        &::before{
        transform: rotateZ(90deg);
        }
        &::after{

        }

      }
      &.clickdo{
        a span::before{
        transform: rotateZ(0deg);
        }
      }

    }//.clickhere
    .clickdown{
      display: none;
      padding-left: 0;
      li{

      }
      li a{
        position: relative;
        padding-left: 40px!important;
        display: inline-block;
        &::before{
          content:"";
          display: inline-block;
          width:10px;
          height: 2px;
          background: #ccc;
          position: absolute;
          top: 50%;
          left:20px;
          -webkit-transform: translate(0%,-50%);
          -moz-transform: translate(0%,-50%);
          -ms-transform: translate(0%,-50%);
          -o-transform: translate(0%,-50%);
          transform: translate(0%,-50%);
        }


      }

    }//clickdown
    span,&::before,&::after{
      transition-property:transform;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .hnbg-menu{
      right: 20px;
      transition-property:right;
      transition-duration: 0.5s;
      transition-timing-function: ease;
      transition-delay: 0s;
      span{
        top: 50%;
        transform: rotateZ(45deg);
      }
      &::before{
        content:"";
        top: 50%;
        transform: rotateZ(-45deg);
      }
      &::after{
        content:"";
        top: 50%;
        transform: rotateZ(45deg);
      }
    }
  }//menu-mode
  //ハンバーガー中身
  .hnbg-nakami{
    display: inline-block;
    position: fixed;
    top: 100vh;
    height: calc(100vh - 80px);
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 200;
    transition-property:top;
    transition-duration: 0.5s;
    transition-timing-function: ease;
    transition-delay: 0s;
    overflow-x: scroll;

    @include min-screen($mqxlX) { //PC
      display: none!important;
    }
    ul{
      overflow-x: scroll;
    }
    ul li{

    }
    li a{
      width: 100%;
      color: #333;
      display: inline-block;
      border-bottom: solid 1px #ccc;
      padding: 15px;
      @include fontsize(14);
    }
  }
  //
  //メインコンテンツ
  //
  .content{
    width: 100%;
    height: auto;
    padding-top: 80px;
    padding-bottom: 50px;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    &.borderbo{
      border-bottom: solid 1px #333333;
    }
  }
  .content-inbox{
    max-width: 1000px;
    margin: 0 auto;
    display: block;
    @include max-screen($mqxlX) { //PC
      width: 90%;
      margin: 0 auto;
    }
    @include max-screen($mqm){ //スマホ
      width: 90%;
      margin: 0 auto;
    }
  }
  .bg_content{
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }//bg_content
  .normal-midashi{
    @include fontsize(22);
    @include max-screen($mqxlX) { //PC
        margin-top: 20px;
        @include fontsize(21);
    }
    @include max-screen($mqm){ //スマホ
        @include fontsize(20);
    }
  }
  .red-midashi{
    color: $base_color_red;
    @include fontsize(26);
    @include max-screen($mqxlX) { //PC
      @include fontsize(24);
    }
    @include max-screen($mqm){ //スマホ
      @include fontsize(22);
    }
  }
  .h2-midashi{
    @include fontsize(35);
    width: 100%;
    text-align: center;
    position: relative;
    padding-bottom: 20px;
    margin-bottom: 30px;
    @include max-screen($mqxlX) { //PC
      @include fontsize(30);
    }
    @include max-screen($mqm){ //スマホ
      @include fontsize(25);
    }
    &::before{
      content:"";
      width: 270px;
      height: 1px;
      background: #808080;
      position: absolute;
      bottom: 0;
      left: 50%;
      -webkit-transform: translate(-50%,0);
      -ms-transform: translate(-50%,0);
      transform: translate(-50%,0);
    }
    &::after{
      content:"";
      height: 3px;
      width: 50px;
      background: #CF121B;
      position: absolute;
      bottom: -1px;
      left: 50%;
      -webkit-transform: translate(-50%,0);
      -ms-transform: translate(-50%,0);
      transform: translate(-50%,0);
    }
    &.midashi-w{
      color: #fff;
    }
    &.midashi-b{
      color: #333;
    }
  }
  .h3-midashi{
    padding: 0px 0  10px 0;
    line-height: 1.3;
    @include fontsize(26);
    @include max-screen($mqxlX) { //PC
      @include fontsize(24);
    }
    @include max-screen($mqm){ //スマホ
      @include fontsize(20);
    }
  }
  .imgsvg{
    width: 90%;
    height: auto;
    @include max-screen($mqxlX) { //PC
      max-width: 80%;
      margin: 0 auto;
      display: block;
    }
    @include max-screen($mqm){ //スマホ
    }
  }//imgsvg
  .specialcont{
    position: fixed;
    top: 0;
    z-index: 0;
    height: auto;
    img{
      margin-left: auto;
      display: block;
      margin-right: 40px;
      @include max-screen($mqxlX) { //PC
        margin: 0 auto;
      }
      @include max-screen($mqm){ //スマホ
        margin: 0 auto;
        width: 100%;
        height: auto;
      }
    }
    .content-inbox{
      max-width: 10000px;
      width: 90%;
    }
    .h3-midashi{
      @include max-screen($mqxlX) { //PC
            margin-top: 30px;
      }
    }
      @include max-screen($mqxlX) { //PC
        position: static;
      }
  }
  .dummy-content{
    width: 100%;
    display: inline-block;
    height: 100vh;
    @include max-screen($mqxlX) { //PC
      display: none!important;
    }
  }
  .shop-list{
    display: inline-block;
    height: auto;
    width: 100%;
    @include fontsize(16);
    @include max-screen($mqm){ //スマホ
      @include fontsize(13);
    }
    h3{
      @include fontsize(22);
      margin-top: 0;
    }
    img{
      width: 100%;
      height: auto;
    }
    a{
      color:#666;
    }
    a.btnred{
      @include fontsize(16);
      color: $base_color_red;
      padding-right: 10px;
      margin-top: 10px;
      @include max-screen($mqm){ //スマホ
        @include fontsize(15);
      }
      i{
        width: 10px;
        height: 10px;
        display: inline-block;
        background-image: url(/assets/images/arrow-r.svg);
        background-size: 10px;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    p{
      margin-bottom: 5px;
    }
    p span{
      display: inline-block;
      width: 90px;
      @include max-screen($mqm){ //スマホ
        width: 60px;
      }
    }
    li{
      background: #fff;
      display: flex;
      flex-wrap: wrap;
      height: auto;
      width: 100%;
      padding: 40px 20px;
      margin-bottom: 40px;
      @include max-screen($mqm){ //スマホ
          padding: 30px 10px;
      }

    }
    li div:nth-of-type(1){
      @include max-screen($mqm){ //スマホ
        margin-bottom: 15px;
      }
    }
    .blog-btn{
      width: 100%;
      height: 100%;
      display: inline-block;
      border-radius: 5px;
      background-size: 100% auto;
      background-repeat: no-repeat;
      background-position: center;
      color: #fff;
      text-align: center;
      overflow: hidden;
      @include fontsize(20);
      position: relative;
      transition-property:font-size;
      transition-duration: 0.3s;
      transition-timing-function: ease;
      transition-delay: 0s;
      @include max-screen($mqxlX) { //PC
        padding: 10px;
        margin-top: 30px;
        height: auto;
      }
      &:hover{
        @include fontsize(25);
        -moz-animation:blogbtnanim 2s 1 forwards;
        -webkit-animation:blogbtnanim 2s 1 forwards;
        animation:blogbtnanim 2s 1 forwards;
        &::before{
          transition-property:width,height,top,left,border;
          transition-duration: 0.3s;
          transition-timing-function: ease;
          transition-delay: 0s;
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          border: solid #fff 0;
        }
      }
      @keyframes blogbtnanim {
        0% {
          background-size: 100% auto;
        }
        100%{
          background-size: 130% auto;
        }
      }
      &::before{
        content:"";
        transition-property:width,height,top,left,border;
        transition-duration: 0.3s;
        transition-timing-function: ease;
        transition-delay: 0s;
        display: inline-block;
        border:solid 2px #fff;
        border-radius: 3px;
        position: absolute;
        z-index: 10;
        left: 5px;
        top: 5px;
        width: calc(100% - 10px);
        height: calc(100% - 10px);
      }
      span{
          @include fontsize(14);
          display: block;
          position: absolute;
          bottom: 30px;
          width: auto;
          margin: 0 auto;
          left: 0;
          right: 0;
          text-align: center;
          padding-left: 15px;
          @include max-screen($mqxlX) { //PC
            position: static;
            bottom: 10px;
          }
          i{
            width: 10px;
            height: 10px;
            display: inline-block;
            background-image: url(/assets/images/arrow-w.svg);
            background-size: 10px;
            background-position: center;
            background-repeat: no-repeat;
          }
        }
      p{
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
          width: 100%;
          text-align: center;
          @include max-screen($mqxlX) { //PC
            position: static;
            -webkit-transform: translate(0%,0%);
            -moz-transform: translate(0%,0%);
            -ms-transform: translate(0%,0%);
            -o-transform: translate(0%,0%);
            transform: translate(0%,0%);
          }
      }
    }

  }
  .service-cont{
    position: relative;
  }
  .about-cont{
    z-index: 20!important;
      @include max-screen($mqxlX) { //PC
        padding-bottom: 50px;
      }
  }
  .item-cont{
    z-index: 14!important;
  }
  .aboutm-cont{
      z-index: 13!important;
      @include min-screen($mqxlX) { //PC
        // padding-bottom: 400px;
      }
  }
  .form-cont{
      z-index: 22!important;
      @include min-screen($mqxlX) { //PC
        // margin-bottom: -100px;
      }
  }
  .shop-cont{
    z-index: 13!important;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    @include max-screen(1101) { //PC
      box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    }
  }
  //serviceコンテンツ
  .service-list{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    li{
      background: #E6E6E6;
      width: 100%;
      margin-bottom: 60px;
      &:nth-of-type(2n){
        .sev-img-box{
          float: right;

        }
        .sev-txt-box{
          float: left;
        }
      }
    }
    a{
      border:solid 1px $base_color_red;
      background: $base_color_red;
      color: #fff;
      @include fontsize(14);
      width: 70%;
      padding: 20px;
      border-radius: 5px;
      display: inline-block;
      position: absolute;
      bottom: 25px;
        @include max-screen($mql){ //タブレット
          width: 100%;
          margin-top: 10px;
          position: relative;
          bottom:auto;
        }
      &:hover{
        background: #fff;
        color: $base_color_red;
        &::after{
          background-image:url(/assets/images/arrow-r.svg);
        }
      }
      &::after{
        content:"";
        width: 10px;
        height: 10px;
        display: inline-block;
        background-image:url(/assets/images/arrow-w.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 10px;
        position: absolute;
        top: 50%;
        right:10px;
        -webkit-transform: translate(0%,-50%);
        -moz-transform: translate(0%,-50%);
        -ms-transform: translate(0%,-50%);
        -o-transform: translate(0%,-50%);
        transform: translate(0%,-50%);
      }

    }
    .sev-img-box{
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      min-height: 360px;
      height: 100%;
      @include min-screen($mqxlX) { //PC
      height: 100%;
      }
      @include max-screen($mqm){ //スマホ
        height: auto;
      }
    }
    h3{
      @include fontsize(26);
      margin-bottom: 20px;
      @include min-screen($mqxlX) { //PC
      @include fontsize(24);
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(22);
      }
    }
    .sev-txt-box{
        height: 100%;
        padding: 40px;
        line-height: 1.5;
        position: relative;
        @include max-screen($mqm){ //スマホ
          padding: 30px;
        }
    }
  }//service-list
  //item
  .item-list{
    // background: #fff;
    display: flex;
    flex-wrap: wrap;
    padding-left: 2px;
    border-radius: 2px;
    overflow: hidden;
    @include max-screen($mqxlX) { //PC
      background: transparent;

    }
    @include max-screen($mqm){ //スマホ
      padding-left: 0;
      padding-top: 2px;
    }
  }//item-list
  .item-etc{
    @include fontsize(22);
    width: 100%;
    text-align: right;
    display: inline-block;
    padding: 10px;
  }
  .left-itemer{
        background: #888;
        overflow: hidden;
        border-radius: 2px;
  }
  .item-box{
    display: flex;
    flex-wrap: wrap;

    // background: #888;
    @include max-screen($mqxlX) { //PC
      background: transparent;

    }
    p{
      color: #fff;
      text-align: center;
      position: relative;
      margin: 0;
      height: 55px;
      background: #888;
      @include max-screen($mqxlX) { //PC
        height: 45px;
        line-height: 1.2;
      }
      span{
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        -moz-transform: translate(-50%,-50%);
        -ms-transform: translate(-50%,-50%);
        -o-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
      }
    }
    li{
      padding:0 1px 1px 0;
      // background: #fff;
      border-radius: 1px;
      @include max-screen($mqm){ //スマホ
        background: transparent;
      }
    }
    img{
      width: 100%;
      height: auto;
    }
  }//item-box
  .statement-list{
    .showd{
      cursor: auto;
      h3{
        cursor: auto;
      }
    }
    .showd .kaihei{
      display: block!important;
    }
    h3{
      background: #E6E6E6;
      text-align: center;
      padding: 20px 0;
      border-radius: 4px;
      cursor: pointer;
      @include fontsize(22);
      color:$base_color_red;
      line-height: 1;
      position: relative;
      @include max-screen($mqm){ //スマホ
        @include fontsize(18);
      }
      span{
            line-height: 0;
            padding-right: 5px;
            position: relative;
            top: -2px;
      }
      i{
        display: inline-block;
        width: 20px;
        height: 20px;
        position: absolute;
        top: 50%;
        right: 20px;
        -webkit-transform: translate(0%, -50%);
        -ms-transform: translate(0%, -50%);
        transform: translate(0%, -50%);
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        transition-duration: 1s;
        transition-timing-function: ease;
        transition-delay: 0s;
        &::after{
          content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            background-image: url(/assets/images/arrow-mrb.svg);
            background-position: center;
            background-repeat: no-repeat;
            background-size: 20px;
            -webkit-transform: rotateZ(180deg);
            -ms-transform: rotate(180deg);
            transform: rotateZ(180deg);
        }
      }
      &.nownow{
        i::after{
          -webkit-transform: rotateZ(0deg);
          -ms-transform: rotate(0deg);
          transform: rotateZ(0deg);
        }
      }
    }

    .kaihei{
      @include fontsize(16);
      text-align: center;
      width: 100%;
      padding: 30px 5px 40px 5px;
      display: none;
      @include max-screen($mqm){ //スマホ
        text-align: left;
        padding: 15px 5px 20px 5px;
        @include fontsize(15);
      }

      span{
        &::before{
          content:"";
          display: inline-block;
          width: 12px;
          height: 12px;
          margin-right: 3px;
          background-color:$base_color_red;
          border-radius: 50%;
        }
      }
    }
  }//statement-list
  //TOP会社概要
  .about-table{
    width: 100%;
    border-top:solid 1px #808080;
    @include max-screen($mqm){ //スマホ
        display: inline-block;
    }
      tr{
        @include max-screen($mqm){ //スマホ
        width: 100%;
        display: inline-block;

        }

      }
      th,td{
        border-bottom:solid 1px #808080;
        padding: 20px 0;
        @include max-screen($mqm){ //スマホ
          display: inline-block;
          width: 100%;
        }
        text-align: left;
      }
      th{
        vertical-align: middle;
        @include max-screen($mqm){ //スマホ
          border-bottom: 0;
          padding: 20px 0 0px 0;
          text-align: center;
        }
      }
      td{

      }
  }//about-table
  a.red-link{
    color: $base_color_red;
    &::before{
      content: "";
      width: 10px;
      height: 10px;
      display: inline-block;
      background-image: url(/assets/images/arrow-r.svg);
      background-position: center;
      background-size: 10px;
      background-repeat: no-repeat;
    }
  }
  .form-table{

    .his{
      color: $base_color_red;
    }
    input[type="submit"] {
       -webkit-appearance: none;

    }
    table{
      width: 100%;
      vertical-align: middle;
      @include max-screen($mqm){ //スマホ
          display: inline-block;
      }
    }
    tr,td,th{
      @include max-screen($mqm){ //スマホ
          display: inline-block;
          width: 100%;
      }
    }
    th,td{
        position: relative;
    }
    th{
      padding: 10px;
      min-width: 220px;
    @include max-screen($mqxlX) { //PC
        min-width: 150px;
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(14);
        padding: 0;
        min-width: 100px;
      }
    }
    td{
      padding: 10px 0;

    }
    input[type="text"],input[type="submit"],textarea,select{
      width: 100%;
      padding: 10px;
      @include max-screen($mqm){ //スマホ
          padding: 20px 10px;
          border-radius: 5px;
      }
    }

    select{
      background: transparent;
      position: relative;
      z-index: 10;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      &::-ms-expand{
        display: none;
      }
      &::-moz-focus-inner{
        border: 0;
      }
      &:focus {
          text-shadow: 0 0 1px #b5b5b5;
      }
      & * {
        -webkit-appearance: none;
        background: #D2D2D2;
      }

    }
    .selecters{
      width: 100%;
      background: #D2D2D2;
      padding: 0;
      &::after{
        content:"";
        z-index: 0;
        width: 14px;
        height: 14px;
        display: inline-block;
        background-image: url(/assets/images/arrow-b.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 14px;
        position: absolute;
        top: 50%;
        right:20px;
        -webkit-transform: translate(0%,-50%);
        -moz-transform: translate(0%,-50%);
        -ms-transform: translate(0%,-50%);
        -o-transform: translate(0%,-50%);
        transform: translate(0%,-50%);
      }
    }
    .subb{
      margin-top: 30px;
    }
    .subb input,.subb button{
      background: #333333;
      color: #fff;
      padding: 20px;
      max-width: 300px;
      width: 90%;
      border-radius: 3px;
      display: block;
      margin: 0 auto;
      border-radius: 5px;
    }
    label,label + input{
      margin-right: 2.5%;
      float: left;
    }
    label{
      width: 10%;
      padding: 10px 0;
      @include max-screen($mqm){ //スマホ
        padding: 0;
      }
    }
    label.seimeilabel + input{
      width: 35%;
    }
    label + input:nth-of-type(2){
      margin-right: 0;
    }
    input[type="text"],input[type="submit"],textarea,select{
      border: solid 1px #B1B1B1;
      border-radius: 3px;
      -webkit-appearance:none;
    }
  }//form-table
  .h3-biketext{
    @include fontsize(40);
    margin-top: 10px;
    margin-bottom: 40px;
    @include max-screen($mqm){ //スマホ
        @include fontsize(32);
    }
    span{
      @include fontsize(26);
      display: block;
      margin-top: 20px;
      @include max-screen($mqm){ //スマホ
        @include fontsize(20);
      }
    }
  }
  .life-list{
    li{
      margin-bottom: 20px;
      &:nth-of-type(2n){
        div:nth-of-type(1){
          float: right;
        }
        div:nth-of-type(2){
          float:left;
        }
      }
    }
    div{
      padding: 10px;
      &:nth-of-type(2){
        padding:10px  20px;
        @include max-screen($mqm){ //スマホ
            padding: 10px 0;
        }
      }
      @include max-screen($mqm){ //スマホ
          padding: 0;
      }
    }
    img{
      width: 100%;
      height: auto;
    }
    h3{
      color: $base_color_red;
      @include fontsize(26);
      margin: 20px 0;
      @include max-screen($mqxlX) { //PC
        margin-top: 0;
      }
      @include max-screen($mqm){ //スマホ
          margin:10px 0;
          @include fontsize(22);
          letter-spacing: -0.025em;
      }
    }
  }//life-list
  //フッター
  footer{
    background: #000;
    position: relative;
    z-index: 10;
    display: inline-block;
    height: auto;
    width: 100%;
  }
  .footer-wrap{
    display: inline-block;
    width: 100%;
    height: auto;
    .footer-navi{
      max-width: 1000px;
      margin: 0 auto;
      position: relative;
      padding:20px 0;
      line-height: 1;
      @include max-screen($mqxlX) { //PC
          padding: 0;
      }
      &::after{
        content:"";
        clear:both;
        display: block;
        @include max-screen($mqxlX) { //PC
            display: none;
        }
      }
      ul{
        list-style: none;
        display: inline-block;
        float: right;
        @include max-screen($mqxlX) { //PC
          float: none;
          width: 100%;
          display: flex;
          flex-wrap: wrap;
        }
      }
      li{
        display: inline-block;
        float: left;
        margin-right: 20px;
        padding: 0 20px 0 0;
        border-right: solid 1px #fff;
        line-height: 1;
        &:nth-last-child(1){
          border:none;
          padding-right: 0;
          margin-right: 0;
        }
        @include max-screen($mqxlX) { //PC
          padding: 0;
          width: 33.333%;
          margin: 0;
        }
      }
      ul li a{
        padding: 0;
        @include fontsize(14);
        color: #fff;
        @include max-screen($mqxlX) { //PC
          display: inline-block;
          padding: 20px 10px;
          @include fontsize(16);
          text-align: center;
          width: 100%;
          line-height: 1.3;
        }
        span{
          @include max-screen($mqxlX) { //PC

          }
        }
        .brsp{
          @include min-screen($mqm){ //スマホ
            display: none;
          }
        }
      }
    }
  }//footer-wrap
  .fot-box{
    background-image:url(/assets/images/bg-fot.jpg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    width: 100%;

  }//fot-box
  .fot-inbox{
    width: 90%;
    margin: 0 auto;
    max-width: 1000px;
    padding: 70px 0;
    @include max-screen($mqxlX) { //PC
      width: 95%;
    }
    @include max-screen($mqm){ //スマホ
      width: 90%;
    }
    .fot-list{
      display: flex;
      flex-wrap: wrap;
    }
    li{
      color: #fff;
      @include fontsize(14);
      padding-bottom: 45px;
      position: relative;
      margin-bottom: 40px;
      p{
        margin-bottom: 5px;
        span{
          display: inline-block;
          min-width: 70px;
        }
      }
      div{
        display: inline-block;
        width: 100%;
        min-height: 150px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        border-radius: 5px;
        overflow: hidden;
      }
      h2{
        @include fontsize(17);
        position: relative;
        overflow: hidden;
        padding-bottom: 20px;
        margin-bottom: 20px;
        margin-top: 10px;
        &::before{
          content:"";
          position: absolute;
          bottom:0;
          width:100%;
          height: 2px;
          display: inline-block;
          background: #fff;
          left: 0;
        }
        &::after{
          content:"";
          position: absolute;
          bottom:0;
          width:30px;
          height: 2px;
          display: inline-block;
          background: $base_color_red;
          left: 0;
        }

      }
      .tellfot{
        @include fontsize(18);
      }
      a{
        color: #fff;
      }
      a.redbtn{
        background: $base_color_red;
        width: 90%;
        padding: 10px 0;
        color: #fff;
        display: inline-block;
        text-align: center;
        margin-top: 20px;
        position: relative;
        border-radius: 5px;
        position: absolute;
        bottom: 0;
        &:hover{
          text-decoration: none;
          background: #fff;
          color:$base_color_red;
          span{
            background-image: url(/assets/images/arrow-mr.svg);
          }
        }
        span{
          display: inline-block;
          width: 10px;
          height: 10px;
          margin-right: 10px;
          background-image: url(/assets/images/arrow-mw.svg);
          background-position: center;
          background-size: 10px;
          background-repeat: no-repeat;
          display: inline-block;
        }
      }
    }
  }//fot-inbox
  .copyright-zone{
    color:  #fff;
    background: #333333;
    text-align: center;
    width: 100%;
    padding: 15px 0;
    @include fontsize(12);
  }//
  //レイジーロード遊び
  .lasyload{
    .about-table{
      border-top: solid 5px #fff;
      transition-property:border;
      transition-duration: 2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .about-table th,.about-table td{
      border-bottom: solid 5px #fff;
      transition-property:border;
      transition-duration: 2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .h2-midashi::before{
      width: 50px;
      transition-property:width,height,background;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .h2-midashi::after{
      width: 270px;
      transition-property:width,height,background;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;
      height:0;
      background: #808080;
    }
    .fot-list h2::after{
          width: 100%!important;
          transition-property:width,height,background;
          transition-duration: 1s;
          transition-timing-function: ease;
          transition-delay: 0s;
    }
    .blog-btn::before{
      border: solid 10px #fff;
      transition-property:width,height,opacity,top,left,border;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;

    }
  }
  .lasyload-load{
    .about-table{
      transition-property:border;
      transition-duration: 2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .about-table th,.about-table td{
      transition-property:border;
      transition-duration: 2s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .h2-midashi::after,.h2-midashi::before{
      transition-property:width,height;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .fot-list h2::after{
      transition-property:width,height,background;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;
    }
    .blog-btn::before{
      transition-property:width,height,opacity,top,left,border;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;

    }
  }
  .alltrev{
    // @include min-screen(1100) { //PC
    //   margin-top: 700px;
    // }
  }
  span.error{
    clear:both;
    margin: 20px 0;
  }

}
// ==================================================
//
//
//
// ==================================================

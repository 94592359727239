
/*▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼ちゅうい▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼▼
==================================================
==================================================

        ~.*.:`.* 注意！~.*.:`.*
             . . . .
  こちらのCSSはシステムベースのため、加筆禁止です。
  ユーザー様による加筆は、別ファイル main-site-user.css
  へお願いします
   万が一加筆された場合、保守管理が難しくなる場合があります
                     ~~~~~~~~~~~~~~~~~~~~~

==================================================
==================================================
▲▲▲▲▲▲▲▲▲注意▲▲▲▲▲▲注意▲▲▲▲▲▲▲注意▲▲▲▲▲▲注意▲▲▲▲▲▲▲▲▲

▲▲▲▲▲▲▲▲▲注意▲▲▲▲▲▲注意▲▲▲▲▲▲▲注意▲▲▲▲▲▲注意▲▲▲▲▲▲▲▲▲

▲▲▲▲▲▲▲▲▲注意▲▲▲▲▲▲注意▲▲▲▲▲▲▲注意▲▲▲▲▲▲注意▲▲▲▲▲▲▲▲▲
*/

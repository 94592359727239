//
//          サイトベース設定
//
//
// ===================================== =============
@mixin fontsize($px){
  font-size: $px + px;
  $aa : $px / 10;
  font-size: $aa + rem;
}
ul,ol{
  list-style: none;
}
h2,h3,h4,h5,h6{
  line-height: 1.5;
}
html{
font-size:62.5%; /*font-size:1.0rem = 10px*/
  &.fontb{
    font-size:77%;
    .content-inbox,.footer-navi,.fot-inbox,.footer-navi{
      max-width: 1350px!important;
    }
  }
}
.lh23{
  line-height: 2.3;
}
body{
  animation: fadeIn 2s ease 0s 1 normal;
-webkit-animation: fadeIn 2s ease 0s 1 normal;
}

@keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}

@-webkit-keyframes fadeIn {
    0% {opacity: 0}
    100% {opacity: 1}
}
a,a:hover,a:hover span,a:hover i,a:hover::after,a:hover::before,a:hover p{
  transition-property:background,color,opacity,transform;
  transition-duration: 1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  text-decoration: none;
  @include max-screen($mqxlX) { //PC
    transition-duration: 0.3s;
  }
}
.lasyload{
  opacity: 0;
  visibility:hidden;
  transition-property:opacity,visibility,transform;
  transition-duration: 1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform:translateY(50px);
  @include max-screen($mqxlX) { //PC
    transition-duration: 0.3s;
  }
}
.lasyload-load{
  opacity: 1;
  visibility: visible;
  transition-property:opacity,visibility,transform;
  transition-duration: 1s;
  transition-timing-function: ease;
  transition-delay: 0s;
  transform:translateY(0);
  @include max-screen($mqxlX) { //PC
    transition-duration: 0.3s;
  }
}
// p,div,span,h1,h2,h3,h4,h5,h6,ul,li,p span{
//   transition-property:font-size;
//   transition-duration: 0.01s;
//   transition-timing-function: ease;
//   transition-delay: 0s;
// }
i{
  font-style: normal;
}
ul,.row,.row div{
  list-style: none;
  margin: 0;
  padding: 0;
}
body{
    -webkit-font-smoothing: antialiased;
  input[type="submit"] {
     -webkit-appearance: none;
  }

  @include fontsize(16);
}
.layout_footer{
  width: 100%;
}
.layout_body{
.search-bg{
      margin-bottom: 50px;
}}
.FS2_ItemThumbnails{
  display: none;
}
.hr-clear{
  width: 100%;
  height: 40px;
  margin: 0;
  padding: 0;
  line-height: 1;
  clear:both;
  border: none;
}//hr-clear
$base_width: 960px;
.txt-center{
  text-align: center!important;
}
.txt-left{
  text-align: left!important;
}
.txt-right{
  text-align: right!important;
}
.txt{
  @include fontsize(16);
  margin-bottom: 0;
  a{
    color:#666;
  }
}
 $base_color_red: #CF121B;
 .color-bcr{
   color: $base_color_red;
 }
 .kadomarr{
   background: $base_color_red;
   color: #fff;
   display: inline-block;
   border-radius: 50%;
   line-height: 1;
   letter-spacing: 1;
   margin-right: 10px;
   padding: 5px 8px;
   @include fontsize(20);
   text-align: center;
 }
// $base_color_black:#323232;
 // $base_color_white:#ffffff;
 // $base_color_glay:#C8C8C8;
// $base_color_p-glay:#DCDCDC;
// $base_color_mp-glay:#C8C8C8;
// $base_color_pink:#F7F2EE;

// $font_jp_b:"FOT-筑紫ゴシック Pr5 D";
// $font_jp_r:"FOT-筑紫ゴシック Pr5 L";
// $font_eng:'"ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", Meiryo, Osaka, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif';


// @for $i from 0 through 100 {
//   .border#{$i} {
//     border-width:#{$i}px!important;;
//   }
// }
// @for $i from 0 through 100 {
//   .w#{$i}ps {
//     width:#{$i}%!important;;
//   }
// }
//
@for $i from 0 through 100 {
  .kadomaru#{$i} {
    overflow: hidden;
    border-radius: #{$i}px!important;
  }
}
@for $i from 9 through 100 {
  .fs#{$i} {
    font-size:#{$i}px!important;
    @include max-screen($mqxlX) { //PC
      $A2 : $i - 2;
      font-size:#{$A2}px!important;
     }
     @include max-screen($mqm){ //スマホ
       $A3 : $i - 4;
       font-size:#{$A3}px!important;
     }
  }
}
@for $i from 0 through 200 {
  .m#{$i} {
    margin:#{$i}px!important;
  }
}
@for $i from 0 through 200 {
  .mt#{$i} {
    margin-top:#{$i}px!important;
  }
}
@for $i from 0 through 200 {
  .mb#{$i} {
    margin-bottom:#{$i}px!important;
  }
}
@for $i from 0 through 200 {
  .mr#{$i} {
    margin-right:#{$i}px!important;
  }
}
@for $i from 0 through 200 {
  .ml#{$i} {
    margin-left:#{$i}px!important;
  }
}



.mrlauto{margin-left: auto!important;margin-right: auto!important;}

@for $i from 0 through 150 {
  .p#{$i} {
    padding:#{$i}px!important;
  }
}
@for $i from 0 through 150 {
  .pl#{$i} {
    padding-left:#{$i}px!important;
  }
}
@for $i from 0 through 150 {
  .pr#{$i} {
    padding-right:#{$i}px!important;
  }
}
@for $i from 0 through 150 {
  .pt#{$i} {
    padding-top:#{$i}px!important;
  }
}
@for $i from 0 through 150 {
  .pb#{$i} {
    padding-bottom:#{$i}px!important;
  }
}
@for $i from 0 through 100 {
  .w#{$i}ps {
    width:#{$i}%!important;
  }
}
.br-sp{
  @include min-screen($mqm){ //スマホ
    display: none;
  }
}
.row-eq-height {
    display: flex;
    flex-wrap: wrap;
}
// @for $i from 0 through 100 {
//   .top#{$i} {
//     top:#{$i}px;position: relative;
//   }
// }
// @for $i from 0 through 100 {
//   .top-#{$i} {
//     top:-#{$i}px;position: relative;
//   }
// }@for $i from 0 through 100 {
//   .bottom#{$i} {
//     bottom:#{$i}px;position: relative;
//   }
// }
// @for $i from 0 through 100 {
//   .bottom-#{$i} {
//     bottom:-#{$i}px;position: relative;
//   }
// }









// $ilink:"/assets2/images/";

// ul{
// 	list-style: none;
// }
// section,nav{
// 	width: 100%;
// 	height: 100%;
// }
// @mixin liststyle{
// 	list-style: none;
// 	margin: 0;
// 	padding: 0;
// 	line-height: 1;
// }
// @mixin hover{
// 	opacity: 0.8;
// 	-moz-opacity:0.8;
// 	filter:alpha(opacity=80);
// 	text-decoration: none;
// 	font-style:none;
// }
// @mixin hovernon{
// 	opacity: 1;
// 	-moz-opacity:1;
// 	filter:alpha(opacity=100);
// }
// a:not(.btn):hover{
// 	opacity: 0.8;
// 	-moz-opacity:0.8;
// 	filter:alpha(opacity=80);
// 	text-decoration: none;
// 	font-style:none;
// }

//
//        サイトベース設定
//
//F+UD-ニューロダン B
// ===================================== =============

@mixin eisu{font-family: "FOT-ニューロダン ProN B";}
@mixin small{font-family: "FOT-筑紫ゴシック Pr5N M";}
@mixin midashi{font-family: "FOT-筑紫ゴシック Pro B";}
body{
      font-family: "FOT-筑紫ゴシック Pr5N M","FOT-ニューロダン ProN B" ,"FOT-筑紫ゴシック Pro B","Helvetica Neue", Helvetica, Arial, sans-serif;
}
.about-table th,.service-list a,.red-button,.kote-list,.fitting-tab,.recruit-tab,.service-p-list a span,button,h2,h3,h4,h5,h6,.gnavi,.blog-btn p,.item-box,.form-table th,.tellfot,.faq-btn span,.big-red-button,.recruit-table th{
      font-family: "FOT-筑紫ゴシック Pro B","FOT-ニューロダン ProN B" ,"Helvetica Neue", Helvetica, Arial, sans-serif;

}
.fontb{
  @include midashi;
}
// .tellfot{
//   @include eisu;
// }
.text-on{
  @include eisu;
  span{
  @include midashi;
  }
}
.eisu{@include eisu;}
// .smallshi{}
// .header-contents li a span{
//   @include midashi;
// }
// .drop-down-titles{
//   @include eisu;
// }
// .post-title-single{
//     font-family: '見出ゴMB31','F+UD-ニューロダン DB';
// }
// .post-date-single{
// font-family: '見出ゴMB31','F+UD-ニューロダン DB';
// }
// .page-slag{
//   @include eisu;
// }
// .side-title{
//   @include midashi;
// }
// .post-list-right span{
//   @include eisu;
// }
// .post-list-right{
//   @include small;
// }
// .btn{
//   font-family: '見出ゴMB31','F+UD-ニューロダン DB';
// }
// .share-title{
//   font-family: '見出ゴMB31','F+UD-ニューロダン DB';
// }
// .post-list-right span{
//   @include eisu;
// }
// #next_title{
//   @include eisu;
// }
// #prev_title{
//   @include eisu;
// }
// .f-share{
//   @include eisu;
// }
// .fot-eigyou-jikan{
//   @include small;
// }
// .copyright span{
//   @include eisu;
// }
// .snsf-title{
//   @include eisu;
// }
// .saisin-title{
//   @include midashi;
// }
// .img-box{
//   @include midashi;
// }
// .tag-title{
//   @include midashi;
// }

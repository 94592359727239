//
//         サイトのベース
//
//メディアクエリ
//$mqss: 0px;
// $mqs: 320px;
// $msm: 480px;
// $mqm: 640px;
// $mql: 768px; viewport
// $mqx: 950px;

// $mqxl: 959px;
//書き方
//@include max-screen($mqxl){}
//
//大きい順に指定しないときかない
//    @include max-screen($mql){ //タブレット
  //  @include max-screen($mqm){ //スマホ
  //  @include max-screen($mqxlX) { //PC
// ===================================== =============
body{
  .oth-head-img{
    height: 300px;
    background: #333;
    color: #fff;
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-color: #000;
    h2{
      @include fontsize(36);
      position: absolute;
      top: 50%;
      left: 50%;
      width: 100%;
      text-align: center;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      -o-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);

      @include max-screen($mqxlX) { //PC
        @include fontsize(35);
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(28);
      }
    }
  }
  .pankuzuzone{
    background: #f7f7f7;
    width: 100%;
    height: auto;
    display: inline-block;
    padding: 10px 5px;
    @include fontsize(16);
    .breadcrumbs{
      max-width: 1000px;
      margin: 0 auto;
      display: block;
      @include max-screen($mqxlX) { //PC
        width: 90%;
      }
    }
    a{
      color: #333;
    }
  }//.pankuzuzone
    //FAQ
  .faqq{
    display: none;
  }
  #nowfaq{
    display: block;
  }
  .faq-midashi{
      background: #333333;
      color: #fff;
      @include fontsize(24);
      display: inline-block;
      width: 100%;
      text-align: center;
      padding: 25px 0;
      border-radius: 5px;
      margin-bottom: 55px;
      @include max-screen($mqxlX) { //PC
          margin-bottom: 30px;
      }
      @include max-screen($mqm){ //スマホ
          margin-bottom: 20px;
      }
  }//faq-midashi
  .faq-btn{
    margin: 60px 0;
    display: flex;
    flex-wrap: wrap;
    cursor: pointer;
    li{
      margin-bottom: 10px;
      @include max-screen($mqxlX) { //PC
        padding: 0 10px;
      }
      span{
        background: #fff;
        border: solid 1px $base_color_red;
        display: inline-block;
        width: 100%;
        text-align: center;
        padding: 15px 0;
        line-height: 1.3;
        color: $base_color_red;
        @include fontsize(18);
        @include max-screen($mqxlX) { //PC
          @include fontsize(16);
        }
        @include max-screen($mqm){ //スマホ
          @include fontsize(15);
        }
      }
      &.acctive-faq{
        span{
          background: $base_color_red;
          color: #fff;
        }
      }
    }
  }//faq-btn
  .ques_box{
    h4{
      background: #e6e6e6;
      border-radius: 5px;
      overflow: hidden;
      display: table;
      width: 100%;
      margin-bottom: 30px;
      margin-top: 30px;
      position: relative;
      cursor: pointer;
    }
    h4 span{
      display: table-cell;
      background: $base_color_red;
      height: 100%;
      width: 60px;
      text-align: center;
      color: #fff;
      @include fontsize(28);
      vertical-align: middle;

    }
    h4 i{
      display: inline-block;
      width: 20px;
      height: 20px;
      position: absolute;
      top: 50%;
      right:20px;
      -webkit-transform: translate(0%,-50%);
      -moz-transform: translate(0%,-50%);
      -ms-transform: translate(0%,-50%);
      -o-transform: translate(0%,-50%);
      transform: translate(0%,-50%);
      transition-property:transform;
      transition-duration: 1s;
      transition-timing-function: ease;
      transition-delay: 0s;
      &::after{
        content:"";
        display: inline-block;
        width: 20px;
        height: 20px;
        background-image: url(/assets/images/arrow-mrb.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: 20px;
        transform: rotateZ(180deg);
      }

    }
    h4 p{
      display: table-cell;
      color: $base_color_red;
      @include fontsize(20);
      padding: 20px 50px 20px 20px;
      vertical-align: middle;
      @include max-screen($mqxlX) { //PC
        @include fontsize(17);
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(15);
      }
    }
    .faqdrop{
      border-bottom: solid 1px #333;
      padding-bottom: 20px;
    }
    &.maintenance_box{
      @include fontsize(20);
      h4 span{
        width: 100px;
        @include fontsize(20);
      }
      &.ques_box{
        h4{
          cursor: auto;
        }
        h4 span{
          width: 100px;
          @include fontsize(20);
        }
      }
    }//maintenance_box
    .faqdrop{
      display: none;
    }
    li.openfaq{
      display: block;

      h4 i{
        &::after{
          transform: rotateZ(0deg);
          transition-property:transform;
          transition-duration: 1s;
          transition-timing-function: ease;
          transition-delay: 0s;
        }
      }
    }
  }
  //maintenance
  .normal-box{
    h3{
      padding: 20px 0 5px 30px;
      @include max-screen($mqxlX) { //PC
        padding: 20px 0 35px 0;
      }
      @include max-screen($mqm){ //スマホ
        padding: 20px 0 35px 0;
      }
    }
    img{

      display: block;
      width: 100%;
      height: auto;
      max-width: 500px;
      @include max-screen($mqxlX) { //PC
        width: 90%;
        margin: 0 auto;
      }
    }
    .imgspan{
      @include max-screen($mqxlX) { //PC
        width: 90%;
        margin: 0 auto;
      }
      img{
        @include max-screen($mqxlX) { //PC
          width: 100%;
          margin: 0 auto;
        }
      }
    }
    div{
      @include max-screen($mqxlX) { //PC
          margin: 0 auto;
          display: block;
          max-width: 100%;
      }
      @include max-screen($mqm){ //スマホ
          margin: 0 auto;
          max-width: 100%;
      }
    }
    p{
      padding: 30px;
      @include max-screen($mqxlX) { //PC
        padding: 0;
      }
      @include max-screen($mqm){ //スマホ
        padding: 0;
      }
    }
  }//normal-box
  .l-hanten{
    float: right;
    @include max-screen(959px) { //PC
      float: none;
    }
  }// l-hanten
 .r-hanten{
    float: left;
    @include max-screen(959px) { //PC
      float: none;
    }
 }// r-hanten
 .red-b-bo-p{
   position: relative;
   padding-bottom: 80px;
   @include max-screen($mqm){ //スマホ
      padding-bottom:  0;
   }
 }//red-b-bo-p
 .red-b-box{
   position: absolute;
   padding: 20px 20px 0 20px;
   bottom: 0;
   @include max-screen(959px) { //PC
        padding: 5px;
        width: 48%;
        margin: auto;
        left: 0;
        right: 0;
   }
   @include max-screen($mqm){ //スマホ
        width: 100%;
        position: static;
   }
   &:nth-of-type(1){
      left: 0;
      right: auto;
      @include max-screen($mqm){ //スマホ
        margin-bottom: 5px!important;
      }
   }
   &:nth-of-type(2){
     right:0;
     left: auto;
   }
 }
 .red-button{
   @include fontsize(14);
   color: #fff!important;
   background: $base_color_red;
   padding: 20px 0;
   display: block;
   text-align: center;
    border-radius: 5px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    width: 100%;
    max-width: 400px;
    border:solid 1px $base_color_red;
    right: 0;
    @include max-screen(959px) { //PC
      position: static;
      left: 0;
    }
    @include max-screen($mqxlX) { //PC
      @include fontsize(13);
    }
    @include max-screen($mqm){ //スマホ
      @include fontsize(12);
    }
    &:hover{
      background: #fff!important;
      color: $base_color_red;
      border:solid 1px $base_color_red;
      span::after{
        background-image: url(/assets/images/arrow-r.svg);
      }
      span{
        color: $base_color_red;
      }
    }
   span{
     &::after{
       content:"";
       display: inline-block;
       text-align: center;
       width: 10px;
       height: 10px;
       background-image: url(/assets/images/arrow-w.svg);
     }
   }
 }//red-button
 .service-p-list{
   .pittari-5{
     width: 20%;
     height: 280px;
     float: left;
     overflow: hidden;
     padding:0 5px;
     @include fontsize(20);
     @include max-screen($mqxlX) { //PC
       padding:10px;
       width: 33%;
       @include fontsize(18);
     }
     @include max-screen($mqm){ //スマホ
      width: 50%;
      height: 200px;
      padding: 5px;
      @include fontsize(16);
     }
   }
   a{
     display: inline-block;
     color: #fff;
     background-size: cover;
     background-position: center;
     background-repeat: no-repeat;
     width: 100%;
     height: 100%;
     position: relative;
     overflow: hidden;
     border-radius: 5px;
     &:hover{
       opacity: 0.8;
     }
     span{
       width: 100%;
       position: absolute;
       top: 50%;
       left: 50%;
       text-align: center;
       -webkit-transform: translate(-50%,-50%);
       -moz-transform: translate(-50%,-50%);
       -ms-transform: translate(-50%,-50%);
       -o-transform: translate(-50%,-50%);
       transform: translate(-50%,-50%);
     }
     i{
       width: 100%;
       text-align:center;
       color: #fff;position: relative;
       bottom: 20px;
       left: 50%;
       position: absolute;
       -webkit-transform: translate(-50%,0);
       -ms-transform: translate(-50%,0);
       transform: translate(-50%,0);
       @include fontsize(14);
       @include max-screen($mqm){ //スマホ
          width: 100%;
          text-align: center;
        }
        &::before{
          content:"";
          display: inline-block;
          width: 10px;
          height: 10px;
          background-image: url(/assets/images/arrow-w.svg);

        }
     }
   }
 }//service-p-list
 //overhaul
 .overhaul-box{
   li{
     background: #E6E6E6;
     margin-bottom: 30px;
     &:nth-of-type(1) h4{background: #C31A1F;}
     &:nth-of-type(2) h4{background: #212875;}
     &:nth-of-type(3) h4{background: #22964E;}
     &:nth-of-type(4) h4{background: #AE4F22;}


     h4{
       color: #fff;
       text-align: center;
       margin: 0;
       cursor: pointer;
       @include fontsize(26);
       padding: 30px 0;
       position: relative;
       @include max-screen($mqxlX) { //PC
         @include fontsize(23);
       }
       @include max-screen($mqm){ //スマホ
         @include fontsize(16);
          padding-right: 25px;
       }
       span{
         position: relative;
         display: inline-block;
         padding-left: 50px;
         @include max-screen($mqxlX) { //PC
           padding-left: 45px;
         }
         @include max-screen($mqm){ //スマホ
           padding-left: 25px;

         }
       }
       span::before{
         content:"";
         position: absolute;
         display: inline-block;
         width: 40px;
         height: 40px;
         left: 0;
        background-image:url(/assets/images/icon-overhall.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        @include max-screen($mqxlX) { //PC
          width: 30px;
          height: 30px;
        }
        @include max-screen($mqm){ //スマホ
          width: 20px;
          height: 20px;
          top: 3px;
          left: 0;
        }
       }
       i{
         display: inline-block;
         width: 20px;
         height: 20px;
         top: 50%;
         right:20px;
         position: absolute;
         -webkit-transform: translate(0%,-50%);
         -moz-transform: translate(0%,-50%);
         -ms-transform: translate(0%,-50%);
         -o-transform: translate(0%,-50%);
         transform: translate(0%,-50%);
       }
       i::after{
         content: "";
         display: inline-block;
         width: 20px;
         height: 20px;
         background-image: url(/assets/images/arrow-mwb.svg);
         background-position: center;
         background-repeat: no-repeat;
         background-size: 20px;
        transform-origin: center;
        transform: rotateZ(180deg);
        position: absolute;
        right: 0;
        top: 0;
        transition-property:transform;
        transition-duration: 1s;
        transition-timing-function: ease;
        transition-delay: 0s;
       }
     }
   }
   width: 100%;
   height: auto;
   display: inline-block;

   h5{
     background: #333333;
     color: #fff;
     border-radius: 5px;
    @include fontsize(16);
   }
   .menulist{
     li{
       border-bottom: solid 1px #333333;
       padding: 10px;
       width: 100%;
       margin-bottom: 0;
     }
   }
 }//overhaul-box
 .lll{
   &:nth-of-type(1){
     padding-right: 20px;
     @include max-screen(959px) { //PC
        padding: 0;
        margin-top: 0px;
     }
   }
   &:nth-of-type(2){
     padding-left: 20px;
     @include max-screen(959px) { //PC
          padding: 0;
          margin-top: 50px;
     }
   }
 }// lll
 .fade-drop{
   padding: 50px;
   display: inline-block;
   width: 100%;
   height: auto;
   display: none;
    &.thisnow{
      display: inline-block;
    }
    @include max-screen($mqxlX) { //PC
        padding: 40px;
    }
    @include max-screen($mqm){ //スマホ
        padding: 30px;
    }
 }//fade-drop
 .dropup{
   h4 i::after{
    transform: rotateZ(0deg)!important;
    transition-property:transform;
    transition-duration: 1s;
    transition-timing-function: ease;
    transition-delay: 0s;
   }
 }//dropup
 .fitting-tab{
   border-bottom: solid 1px #666;
   @include max-screen($mqxlX) { //PC
      border: none;
   }
   ul{
     max-width: 1000px;
     margin: 0 auto;
   }
   li{
     cursor: pointer;
      width: 50%;
      height: auto;
      @include fontsize(16);
      text-align: center;
      height: 100px;
      overflow: hidden;
      position: relative;
      top: 1px;
      z-index: 20;
      @include max-screen($mqxlX) { //PC
        @include fontsize(13);
        overflow: auto;
        height: auto;
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(12);
      }
      p span{
        @include fontsize(22);
        @include max-screen($mqxlX) { //PC
          @include fontsize(20);
        }
        @include max-screen($mqm){ //スマホ
          @include fontsize(18);
        }
      }
      p{
        margin: 0;
        padding: 25px 0;
        border: solid 1px #666666;
        border-radius: 5px;
        @include max-screen($mqxlX) { //PC
            height: 100%;
        }
      }
      &.tab1-on{
        color: $base_color_red;
        p{
          background: #fff;

        }
      }
      &.tab1-off{
        color: #fff;
        p{
          background: #666666;
        }
      }
      &.tab2-off{
        color: #fff;
        p{
          background: #666666;
        }
      }
      &.tab2-on{
        color: #009AE3;
        p{
          background: #fff;

        }
      }
   }
 }//fitting-tab
 //fitting
 .glay-midashi{
   background: #E6E6E6;
   @include fontsize(26);
   padding: 20px 0;
   border-radius: 5px;

   span{
     position: relative;
    top: -5px;
     @include fontsize(16);
   }
 }//glay-midashi
 .fishing-list{
   display: flex;
   flex-wrap: wrap;
   margin-left: -15px;
   margin-right: -15px;
   li{
      margin-bottom: 20px;
   }
   p{
     @include fontsize(14);
     text-align: justify;
    text-justify: inter-ideograph;
    -ms-text-justify: inter-ideograph;
    line-height: 1.7;
   }
 }//fishing-list
 .border-midashi{
   border-bottom: solid 1px #D2D2D2;
   padding: 10px 0;
   margin: 5px 0 10px 0;
    min-height: 65px;
    @include max-screen($mqxlX) { //PC

    }
    @include max-screen($mqm){ //スマホ
      margin-top: 0;
    }
 }//border-midashi
 .black-midashi{
    @include fontsize(22);
    background: #333333;
    color:#fff;
    padding: 25px 0;
    width: 100%;
    border-radius: 5px;
    @include max-screen($mqxlX) { //PC
  @include fontsize(18);
    }
 }
 .menubox{
   background: #E6E6E6;
   display: inline-block;
   width: 100%;
   height: auto;
   padding: 20px 40px;
   border-radius: 5px;
   margin-bottom: 50px;
   @include max-screen($mqxlX) { //PC
    padding: 20px 30px;
   }
   @include max-screen($mqm){ //スマホ
     padding: 20px 20px;
   }
   span{
     display: block;
     margin-top: 20px;
     margin-bottom: 5px;
   }
   li span{
     margin: 0;
    display: table-cell;

   }
 }//menubox
 .kote-list{
   width: 100%;
   height: auto;
    padding: 0 10px;
    @include max-screen($mqxlX) { //PC
       margin-top: 20px;
    }
    @include max-screen($mqm){ //スマホ
      margin-top: 20px;
    }
   li{
     display: table;
     background: #fff;
     width: 100%;
     margin-bottom: 10px;
     p{
       display: table-cell;
       padding: 15px;
     }
     span{
       display: table-cell;
       width: 120px;
       background: $base_color_red;
       color: #fff;
       text-align: center;
       padding: 15px 0;
     }
   }
 }//
 .content-tabbox{
   display: none;
 }
 .syokiactiv{
   display: block;
 }

 //recruit
 .recruit-tab{
   border-bottom: solid 1px #666;

   @include max-screen($mqxlX) { //PC
      border: none;
   }
   ul{
     max-width: 1000px;
     margin: 0 auto;
     width: 100%;
     display: flex;
     flex-wrap: wrap;
   }
   li{
     cursor: pointer;
      width: 50%;
      height: auto;
      @include fontsize(16);
      text-align: center;
      height: 80px;
      overflow: hidden;
      position: relative;
      top: 1px;
      z-index: 20;
      @include max-screen($mqxlX) { //PC
        @include fontsize(13);
        overflow: auto;
        height: auto;
      }
      @include max-screen($mqm){ //スマホ
        @include fontsize(12);
      }
      p span{
        @include fontsize(22);
        @include max-screen($mqxlX) { //PC
          @include fontsize(20);
        }
        @include max-screen($mqm){ //スマホ
          @include fontsize(15);
        }
      }
      p{
        margin: 0;
        padding: 25px 0;
        border: solid 1px #666666;
        border-radius: 5px;
        @include max-screen($mqxlX) { //PC
            height: 100%;
        }
      }
      &.tab1-on,&.tab2-on{
        color: $base_color_red;
        p{
          background: #fff;
        }
      }
      &.tab1-off,&.tab2-off{
        color: #fff;
        p{
          background: #666666;
        }
      }
   }
 }//recruit-tab
 .recruit-table{
   width: 100%;
   margin-bottom: 70px;
   table{
     width: 100%;
     margin-top: 100px;
     border-top: solid 1px #808080;
   }
   th{
     min-width: 100px;
   }
   i{
      margin-right: 10px;
   }
   td,th{
     border-bottom: solid 1px #808080;
     padding: 20px 0;
     vertical-align: top;
   }
 }//recruit-table
 .seinen{
  width:35%;
 }
   .getu{
  width: 15%;
   }
   .hiday{
  width: 15%;
   }
  .seinen,.getu,.hiday{
    float: left;
    input{
      width: 70%!important;
      margin-right: 2%;
      @include max-screen($mqm){ //スマホ
        width:80%!important;
      }
    }
    @include max-screen($mqm){ //スマホ
        width: 100%!important;
        margin-bottom: 20px;
    }
  }
  .recruit-tabbox{
    display: none;
    &.syokiactiv{
      display: block;
    }
  }
  .radio-inline{
    width: auto!important;
    margin-left: 20px;
    input{
      margin-right: 5px!important;
      border-radius: 50%;
      margin-left: -16px!important;
    }
  }
  .mwform-radio-field-text{
    display: inline-block;
    width: 60px;
  }
  //
  .numicon{
    width: 14px;
    height: 14px;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    display: inline-block;
    margin-right: 5px;
    &.alphab{
      width: 24px;
      height: 24px;
      position: relative;
      top: 3px;
    }
  }//numicon
  //
  .big-red-button{
    display: block;
    max-width: 1000px;
    color: #fff;
    background: $base_color_red;
    width: 100%;
    text-align: center;
    padding: 35px 0;
    margin: 0 auto;
    border-radius: 5px;
    border:solid 1px $base_color_red;
    @include fontsize(20);
    @include max-screen($mqxlX) { //PC
      @include fontsize(18);
      margin: 20px auto;
    }
    @include max-screen($mqm){ //スマホ
      @include fontsize(16);
      margin: 10px auto;
    }
    span{
      &::after{
        content:"";
        display: inline-block;
        width: 18px;
        height: 18px;
        background-image: url(/assets/images/arrow-bbwr.svg);
        background-repeat: no-repeat;
        background-size: 18px;
        margin-left: 10px;
        position: relative;
        top: 2px;
      }
    }
    &:hover{
      color: $base_color_red;
      background: #fff;
      span{
            color: $base_color_red;
            &::after{
              background-image: url(/assets/images/arrow-bbrr.svg);
            }
      }
    }
  }//big-red-button
}
// ==================================================
//
//       /page
//
// ==================================================

// ==========================================================================
//           Faoundation
// ==========================================================================


//現在のbower_componentsの場所
// "./../bower_components";


// -----------------------------------
//           attention
// -----------------------------------
/*SHOP移植用*/
//div.shopsite-box{
 @import "_foundation/_base/_attention";




// -----------------------------------
//           Variable
// -----------------------------------





// -----------------------------------
//           FONT
// -----------------------------------
// @import "./_foundation/_fonts/fonts";



// -----------------------------------
//           COMPASS
// -----------------------------------

// @import "./../bower_components/compass-mixins/lib/compass/functions";
//@import "./../bower_components/compass-mixins/lib/compass/utilities";
//@import "./../bower_components/compass-mixins/lib/compass/typography";
// @import "./../bower_components/compass-mixins/lib/compass/css3";
// @import "./../bower_components/modernizr-mixin/stylesheets/modernizr";








//@import "_foundation/_slick/slick";

// -----------------------------------
//           bxslider-4
// -----------------------------------

// $bx-path--loader-image:"../images/common_project/bx_loader.gif" !default;
// $bx-path--controls-image:"../images/common_project/controls.png" !default;
// @import "_plugins/jquery.bxslider";
// .bx-wrapper .bx-controls-direction a{
//   z-index:0;
// }




// -----------------------------------
//           Mix-in
// -----------------------------------
//   メディアクエリ用
 @import "_foundation/_mixins/mq";
// @import "_foundation/_mixins/mq_custom";
// @import "_foundation/_mixins/mixins-master";
// @import "_foundation/_mixins/mixins.font";
// @import "_foundation/_mixins/mixins.centering";
// @import "_foundation/_mixins/mixin.project.common";

// -----------------------------------
//           Bootstrap
// -----------------------------------
@import '_foundation/_variables/_variables';
@import '_foundation/_bootstrap/bootstrap';
 @import '_foundation/_offcanvas/offcanvas';

// @import "_foundation/_bootstrap/_non-responsive";/*レスポンシブ無効化*/


// -----------------------------------
//          slider
// -----------------------------------
 @import "_foundation/_slick/slick.scss";
 @import "_foundation/_slick/slick-theme.scss";

// -----------------------------------
//           base
// -----------------------------------


@import '_foundation/_base/base';/*べースカラーとか基本変数*/
@import '_foundation/_base/font';/*font*/
@import '_fontawesome/scss/font-awesome';/*フォントアウェサム*/


// -----------------------------------
//           base2
// -----------------------------------
@import "_foundation/_layout/layout";/*サイトの基本構造*/



//           page
// -----------------------------------
@import "_pages/_single";/*singleページ*/
@import "_pages/_category";/*categoryページ*/
@import "_pages/_page";/*固定ページ*/
// -----------------------------------
//          fontawesome
// -----------------------------------
// フォントファイルのパスを変更
 //$fa-font-path:        '../fonts/fontawesome' !default;
// $fa-line-height-base:$line-height-base;
//@import './_fontawesome/scss/font-awesome.scss';



// -----------------------------------
//           mmenu
// -----------------------------------


//@import "_plugins/jquery.mmenu.all";


// -----------------------------------
//           pushy
// -----------------------------------

//$menu_width: 400px;

//@import "../bower_components/pushy/scss/pushy";
//@import "./_plugins/pushy";


// -----------------------------------
//           sidr
// -----------------------------------


// @import "_plugins/sidr/jquery.sidr.bare";

//}

// -----------------------------------
//           outdated browser
// -----------------------------------
//@import "../bower_components/outdated-browser/outdatedbrowser/outdatedbrowser.scss";


// -----------------------------------
//          アイコンフォント
// -----------------------------------
//@import "../../-tmp/_project-icons.scss";













// ==========================================================================
//           Layout
// ==========================================================================

// @import './_layout/_layout.scss';


// ==========================================================================
//           Objects
// ==========================================================================

// -----------------------------------
//         typography
// -----------------------------------
//@import '_objects/objects.typography.copy';
//@import '_objects/objects.typography.headings';
//@import '_objects/objects.typography';


// -----------------------------------
//         entry
// -----------------------------------
// @import './_objects/_objects.article.scss';
// @import './_objects/_objects.entry.title.scss';
// @import './_objects/_objects.entry.meta.scss';
//
// @import './_objects/_objects.content-feature.scss';


// -----------------------------------
//         パンくず
// -----------------------------------
//@import '_objects/objects.breadcrumb';

// -----------------------------------
//         svg sprite
// -----------------------------------
// @import './_objects/_objects.sprite.svg.scss';
// @import './_objects/_objects.sprite.svg.common-project.symbol.scss';



// -----------------------------------
//         wordpress
// -----------------------------------
//@import "_objects/objects";
//@import "_objects/objects.floating-menu";
//@import "_objects/objects.site-aside";
// @import './_objects/_objects.wp.media.captions';
// @import './_objects/_objects.wp.media.galleries';
// @import './_objects/_objects.wp.media';
// @import './_objects/_objects.wp.menus';
// @import './_objects/_objects.wp.pagenavi';
// @import './_objects/_objects.wp.widget';
// @import './_objects/_ogjects.wp.alignments';
// @import './_objects/_objects.wp.pager-archive';



// -----------------------------------
//         snsボタン
// -----------------------------------
// @import "./_objects/_objects.sns-button.scss";


// -----------------------------------
//        求人用パーツ
// -----------------------------------
// @import "./_objects/_objects.recruitment.scss";



//秋葉山
//@import "_akiba/my-variables";
//@import "_akiba/mixins";
//@import "_akiba/common";

// ==========================================================================
//    Pages
// ==========================================================================




// @mixin hover-effect01(){
//   transition-duration:0.5s;
//   opacity: 0.7;
//   color: lighten($brand-black,20%);
//   background: lighten($brand-black,80%);
// };


// @mixin hover-effect02(){
//   transition-duration:0.5s;
//   opacity: 0.7;
//   color: lighten($brand-black,20%);
// };







// @import '_pages/pages';

// -----------------------------------
//     Pages.types.**   ページの種類ごとのスタイル
// -----------------------------------
//  @import "./_pages/_pages.type.single.scss"; //お知らせ
//  @import "./_pages/_pages.type.single.press.scss"; //プレス

// @import "./_pages/_pages.type.news-archives";
// @import "./_pages/_pages.type.press-archives";
//




// -----------------------------------
//     Pages.page.**    ページ単位でのスタイル
// -----------------------------------
 // @import './_pages/_pages.page.front';
 // @import './_pages/_pages.page.vision';
 // @import './_pages/_pages.page.village';
 // @import './_pages/_pages.page.company';
 // @import './_pages/_pages.page.honten'; //結わえる本店
 // @import './_pages/_pages.page.honten.lunch'; //一膳飯屋
 // @import './_pages/_pages.page.honten.dinner'; //一膳飯屋
 // @import './_pages/_pages.page.honten.store'; //よろずや
 // @import './_pages/_pages.page.fhs.takikata'; //
 // @import './_pages/_pages.page.honten.shodou'; //
 // @import './_pages/_pages.page.fhs'; //
 // @import './_pages/_pages.page.central-kitchen'; //
 // @import './_pages/_pages.page.recruit'; //recruit



// ==========================================================================
//    Utility
// ==========================================================================
// マージンの調整など
// @import "_utility/utility";
// @import "_utility/utility.display";
// @import "_utility/utility.letter-spacing";
// @import "_utility/utility.line-height";
// @import "_utility/utility.spacing";
// @import "_utility/utility.text-align";
// @import "_utility/utility.vertical-align";
// @import "_utility/utility.font-size";
// @import "_utility/utility.float";
